import React from 'react';
import HomeB from './HomeB';
import HomeC from './HomeC';
import HomeD from './HomeD';
import HomeE from './HomeE';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import ModalVideo from './ModalVideo';
import VideoThumb from './Nulfye.webp';
import './css.css';

const HomeA = () => {
  
return (

  <>
  <Helmet>
  <title>AI Content Writer: Generate Quality with One Click - Nulfye</title>
  <meta name="description" content="Nulfye is the authentic AI content writer for effortless content creation. Get premium, SEO-optimized content of top quality for free, with just one click." />
  </Helmet>

  <section>

  <div className="max-w-5xl mx-auto px-2">
  <div className="relative pt-10 pb-8 md:pt-12 md:pb-12 lg:pb-16 lg:pt-16">
  <div className="text-center pb-6 lg:pb-12">
  <h1 className="text-3xl md:text-4xl lg:text-5xl font-semibold leading-tighter tracking-tighter text-left md:text-center mb-3 " data-aos="zoom-y-out"> AI Content Writer : <span className="bg-clip-text text-transparent bg-gradient-to-r from-orange-500 to-yellow-500">Generate Quality with a Single Click</span></h1>
  <p className="text-left md:text-center text-[18px] md:text-[20px] md:mt-2 lg:text-[22px] text-gray-600" data-aos="zoom-y-out" data-aos-delay="150" >An AI Content Writer is a sharp tool that helps you create great articles, blogs, or social media posts in no time. With just one click, your ideas turn into distinct, quality content. It's simple, fast, and perfect for school projects, sharing online, or just having fun with writing. This incredible tool makes writing easy and exciting for everyone.</p>
  </div>

  {/* Macbook */}
  <div class="macbook" className="hidden lg:block">
  <div class="display">
  <div class="screen">

  <ModalVideo thumb={VideoThumb} thumbAlt="Modal video thumbnail" video='/convid/nulli.mp4' videoWidth={1920} videoHeight={1080}/>
  </div></div><div class="base"><div class="indent"></div></div><div class="bottom"></div></div>
  
  {/* Ipad */}
  <div className="hidden md:block lg:hidden">
  <div class="ipad-pro-portrait" >
  <div class="bezeln"></div>
  <div class="casingn"></div>
  <div class="cameran"></div>
  <div class="home-btnn"></div>
  <div class="screenn">

  <ModalVideo as={Fragment} thumb={VideoThumb} thumbAlt="Modal video thumbnail" video='/convid/nulli.mp4' videoWidth={1920} videoHeight={1080}/>
  </div></div></div>

  {/* Iphone 14 */}
  <div className='flex flex-wrap justify-center mb-4'>
  <div class="iphone" className=" md:hidden lg:hidden ">
  <div class="iphone-14"> 
  <div class="operator-name">Airtel</div>
  <div class="dynamic-island"></div>
  <div class="dynamic-island-camera"></div>
  <div class="operator-icons"><i class="bi bi-reception-4"></i>5G<div class="battery">100</div></div><div class="date-area"></div>
  
  <ModalVideo as={Fragment} thumb={VideoThumb} thumbAlt="Nulfye explains how it generate an article with just a single click" 
   video='/convid/nulli.mp4' videoWidth={1920} videoHeight={1080}/></div></div></div></div></div>

  </section>

  <div className='mb-16 lg:mb-20'><HomeB/></div>
  <div className='mb-16 md:mb-20 lg:mb-24'><HomeC/></div>
  <div className='mb-16 md:mb-20 lg:mb-24'><HomeD/></div>
  <div className='mb-8 md:mb-12 lg:mb-16'><HomeE/></div>
  </>
 
  )};

export default HomeA;
