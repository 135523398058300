import React from 'react';
import { Link , useHistory} from "react-router-dom";


const Footer = () => {
  const history = useHistory();
  
  const handleIconButtonClick = pageUrl => {
        history.push(pageUrl)
  }   
  
  

  
 return(
    <>

    <footer className="position-relative h-[120px] md:h-[80px] inline-block p-4 bg-white border-t border-gray-200 shadow md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800 dark:border-gray-600">
    
    <ul className= "flex flex-wrap items-center justify-center mt-1 text-sm font-medium text-gray-500 dark:text-gray-400 ml-2 flex space-x-3 ...">
    <li><Link onClick={() => handleIconButtonClick ("/terms-of-service/")}  className="mr-4 hover:underline md:mr-6 ">Terms of Service</Link></li>
    <li><Link onClick={() => handleIconButtonClick ("/disclaimer/")}  className="mr-4 hover:underline md:mr-6 ">Disclaimer </Link></li>
    <li><Link onClick={() => handleIconButtonClick ("/privacy-policy/")}   className="mr-4 hover:underline md:mr-6">Privacy Policy</Link></li>
    <li><Link onClick={() => handleIconButtonClick ("/contact/")}   className="mr-4 hover:underline md:mr-6">Contact</Link></li>
    </ul>
    
    <span className="text-sm items-center text-[#020617] sm:text-center md:text-center inline-block ">Copyright © 2024&nbsp;Nulfye </span>
    </footer>
  

    </>
  );
  }   

export default Footer;