import React from 'react';
import './css.css';
import Grid from '@mui/material/Grid';

const HomeD = (props)  => {
  
return(

    <>
    <Grid item container >
    <Grid item xs={false} sm={false} md={1} lg={1.5} />
    <Grid item xs={12} sm={12} md={10} lg={9}>
    
    <div className="border-t border-gray-300">

    <h3 class="text-gray-800 text-center text-2xl md:text-3xl lg:text-4xl mt-8 md:mt-12 lg:mt-16 hover:underline py-2 md:py-6 ">How Content writing Tools Works</h3>
    <div class="px-2 flex flex-col items-center flex-wrap lg:flex-row lg:items-stretch lg:flex-no-wrap lg:justify-between">
    <div class="w-full max-w-sm mt-8 lg:mt-8 rounded shadow-lg p-8  lg:mx-4 xl:p-12">
    <div class="p-4 inline-block bg-indigo-200 rounded-lg">
              
    <svg className='h-[40px] w-[40px] md:h-[60px] md:w-[60px] lg:h-[80px] lg:w-[80px]' viewBox="0 0 384 512" fill="currentColor" {...props}>
    <path d="M112.1 454.3c0 6.297 1.816 12.44 5.284 17.69l17.14 25.69c5.25 7.875 17.17 14.28 26.64 14.28h61.67c9.438 0 21.36-6.401 26.61-14.28l17.08-25.68c2.938-4.438 5.348-12.37 5.348-17.7l.128-39.2H112l.1 39.2zM192 0C90.02.32 16 82.97 16 175.1c0 44.38 16.44 84.84 43.56 115.8 16.53 18.84 42.34 58.23 52.22 91.45.031.25.094.517.125.782h160.2c.031-.265.094-.516.125-.782 9.875-33.22 35.69-72.61 52.22-91.45C351.6 260.8 368 220.4 368 175.1 368 78.8 289.2.004 192 0zm96.4 260.1c-15.66 17.85-35.04 46.3-49.05 75.89h-94.61c-14.01-29.59-33.39-58.04-49.04-75.88C75.24 236.8 64 206.1 64 175.1 64 113.3 112.1 48.25 191.1 48 262.6 48 320 105.4 320 175.1c0 31-11.2 61.7-31.6 85zM176 80c-44.1 0-80 35.9-80 80 0 8.844 7.156 16 16 16s16-7.2 16-16c0-26.47 21.53-48 48-48 8.844 0 16-7.148 16-15.99S184.8 80 176 80z" />
    </svg>
    </div>
            
    <div class="mt-4 font-extrabold text-xl md:text-2xl tracking-wide  mb-4">Instruct your Content Writter Tool</div>
    <div class="text-md md:text-lg text-left">Enter your keyword in the Textfield and any instructions into the Content writter tool system.</div>
    </div>
    <div class="w-full max-w-sm mt-8 rounded shadow-lg p-8 lg:mx-4 xl:p-12">
    <div class="p-4 inline-block bg-green-200 rounded-lg">
    <svg  className='h-[40px] w-[40px] md:h-[60px] md:w-[60px] lg:h-[80px] lg:w-[80px]'  viewBox="0 0 1024 1024"  fill="currentColor"  {...props}>
    <path d="M864 736c0-111.6-65.4-208-160-252.9V317.3c0-15.1-5.3-29.7-15.1-41.2L536.5 95.4C530.1 87.8 521 84 512 84s-18.1 3.8-24.5 11.4L335.1 276.1a63.97 63.97 0 00-15.1 41.2v165.8C225.4 528 160 624.4 160 736h156.5c-2.3 7.2-3.5 15-3.5 23.8 0 22.1 7.6 43.7 21.4 60.8a97.2 97.2 0 0043.1 30.6c23.1 54 75.6 88.8 134.5 88.8 29.1 0 57.3-8.6 81.4-24.8 23.6-15.8 41.9-37.9 53-64a97 97 0 0043.1-30.5 97.52 97.52 0 0021.4-60.8c0-8.4-1.1-16.4-3.1-23.8H864zM762.3 621.4c9.4 14.6 17 30.3 22.5 46.6H700V558.7a211.6 211.6 0 0162.3 62.7zM388 483.1V318.8l124-147 124 147V668H388V483.1zM239.2 668c5.5-16.3 13.1-32 22.5-46.6 16.3-25.2 37.5-46.5 62.3-62.7V668h-84.8zm388.9 116.2c-5.2 3-11.2 4.2-17.1 3.4l-19.5-2.4-2.8 19.4c-5.4 37.9-38.4 66.5-76.7 66.5-38.3 0-71.3-28.6-76.7-66.5l-2.8-19.5-19.5 2.5a27.7 27.7 0 01-17.1-3.5c-8.7-5-14.1-14.3-14.1-24.4 0-10.6 5.9-19.4 14.6-23.8h231.3c8.8 4.5 14.6 13.3 14.6 23.8-.1 10.2-5.5 19.6-14.2 24.5zM464 400a48 48 0 1096 0 48 48 0 10-96 0z" />
    </svg>
    </div>
            
    <div class="mt-4 font-extrabold text-xl md:text-2xl tracking-wide  mb-4">Wait some Seconds</div>
    <div class="text-md md:text-lg text-left">During those seconds, Content Writter will intelligently research, plan out, and write an entire high quality, completely unique Content automatically.</div>
    </div>
    <div class="w-full max-w-sm mt-8 rounded shadow-lg p-8 lg:mx-4 xl:p-12">
    <div class="p-4 inline-block bg-red-200 rounded-lg">
    <svg className='h-[40px] w-[40px] md:h-[60px] md:w-[60px] lg:h-[80px] lg:w-[80px]' fill="none" viewBox="0 0 15 15"  {...props}>
    <path stroke="currentColor" d="M11 1.5h2.5v12a1 1 0 01-1 1h-10a1 1 0 01-1-1v-12H4m1 7l2 2 3.5-4m-6-6h6v2a1 1 0 01-1 1h-4a1 1 0 01-1-1v-2z" />
    </svg>
    </div>
    <div className="mt-4 font-semibold text-xl md:text-2xl tracking-wide mb-4"> Receive your Content In no Time </div>
    <div class="text-left text-md md:text-lg">That's it! You have your Content and can do whatever you want with it.</div> 
    </div> </div> </div>
  
    </Grid>
    < Grid item xs={false} sm={false} md={1} lg={1.5} />
    </Grid>
    
    </>
    )}

export default HomeD;