import React from 'react';

const HomeC = ()  => {
  
return(
  <>
  <section>
  
  <div className="max-w-6xl mx-auto px-2">
  <div className="border-t border-gray-300">

  <div className="max-w-4xl mx-auto mt-2 pb-8 md:pb-12 lg:pb-16 pt-10 md:pt-12 lg:pt-16">
  <h2 class="text-center md:text-center text-2xl md:text-3xl lg:text-4xl font-medium hover:underline text-gray-800">AI Content Writer Tools: Smart Solutions for Quality Content</h2>
  </div>

  <div className="grid gap-16">
  <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
  <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1 " data-aos="fade-up">
  <img className="mx-auto h-[350px] w-[300px] md:h-[450px] md:w-[400px] lg:h-[550px] lg:w-[500px]" src='/conpic/SEO.webp'  alt="feature 02" />
  </div>

  <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-right">
  <div className="md:pr-6 lg:pr-10 xl:pr-12">
  <div className="font-architects-daughter"></div>
  <h3 className="text-[21px] md:text-2xl lg:text-3xl font-medium text-left text-left lg:mb-8 mb-4" >SEO Optimized Content</h3>
  <p className="text-left text-[18px] lg:text-[20px] text-gray-600"> SEO-optimized content helps improve a website’s rank on search engines like Google, making it more visible to users. It focuses on using the right keywords, creating compelling titles, and ensuring the content is easy to read, making it more appealing to users and search engines.<br/><br/> AI tools assist in producing this content quickly by identifying the best keywords and effectively organizing the content. These tools optimize elements such as headings, meta descriptions, and images so search engines can easily understand the content. Whether for blogs, product pages, or social media posts, SEO-optimized content helps attract more visitors to a website. <br/><br/>As a result, businesses can increase their online presence and grow their audience. With AI-powered tools, creating SEO-friendly content becomes faster, easier, and more efficient.</p>
  </div></div></div>

  <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
  <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:mb-0 rtl" data-aos="fade-up">
  <img className=" mx-auto h-[300px] w-[300px] md:h-[450px] md:w-[400px] lg:h-[550px] lg:w-[500px]"  src='/conpic/update.webp'  alt="Features 03" />
  </div>
  <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-right">
  <div className="md:pr-6 lg:pr-10 xl:pr-12">
  <h3 className="text-[21px] md:text-2xl lg:text-3xl font-medium text-left lg:mb-8 mb-4">Scalability</h3>
  <p className="text-left text-[18px] lg:text-[20px] text-gray-600">The scalability of an AI content writer means it can easily handle more content creation as the demand grows. Whether you're writing a few blog posts or hundreds of articles, the AI system can adjust to produce more content without slowing down. <br/><br/>As your business expands, AI can quickly create a large amount of content, helping you keep up with the increasing need. AI content writers are great for enterprises that must produce a large amount of content regularly. They help save time, improve efficiency, and still produce high-quality work.</p>
  </div></div></div></div></div></div> 

  </section>
  </>

  )}

export default HomeC;